import toInteger from 'lodash/toInteger';
import { isNumber } from 'lodash';
import { ADDITIONAL_TABS } from '../../constants/appTabs';
import { COMPONENTS } from '../../constants/components';
import { routes } from '../../routes/routesData';

const actions = {

	APP_TABS_RELOAD  	: 'APP_TABS_RELOAD',
	APP_TABS_REFRESH 	: 'APP_TABS_REFRESH',
	APP_TABS_OPEN_TAB	: 'APP_TABS_OPEN_TAB',
	APP_TABS_CLOSE_TAB: 'APP_TABS_CLOSE_TAB',
	APP_TABS_CLOSE_ALL: 'APP_TABS_CLOSE_ALL',

	APP_TABS_CLOSE_OTHER_TABS: 'APP_TABS_CLOSE_OTHER_TABS',
	APP_TABS_CLOSE_RIGHT_TABS: 'APP_TABS_CLOSE_RIGHT_TABS',

	APP_TABS_ACTIVE_TAB_SET: 'APP_TABS_ACTIVE_TAB_SET',

	APP_TABS_ACTIVE_USER_ID_SET: 'APP_TABS_ACTIVE_USER_ID_SET',

	tabsReload: () => ({
		type: actions.APP_TABS_RELOAD,
	}),
	tabsRefresh: tabs => ({
		type: actions.APP_TABS_REFRESH,
		data: { tabs },
	}),
	activeTabSet: (activeTabID, activeTabTitle, isSearch) => ({
		type: actions.APP_TABS_ACTIVE_TAB_SET,
		data: {
			activeTabID,
			activeTabTitle,
			isSearch,
		},
	}),
	openTab: (tab, usePush = true ) => {

		return {
			type: actions.APP_TABS_OPEN_TAB,
			data: {
				tab,
				usePush,
			},
		};
	},
	closeTab: (tabID, closeContext) => ({
		type: actions.APP_TABS_CLOSE_TAB,
		data: {
			tabID,
			closeContext,
		},
	}),
	closeOtherTabs: tabID => ({
		type: actions.APP_TABS_CLOSE_OTHER_TABS,
		data: {
			tabID,
		},
	}),
	closeRightTabs: tabID => ({
		type: actions.APP_TABS_CLOSE_RIGHT_TABS,
		data: {
			tabID,
		},
	}),
	setTabUserId: userID => ({
		type: actions.APP_TABS_ACTIVE_USER_ID_SET,
		data: {
			userID,
		},
	}),
	closeAllTabs: () => ({
		type: actions.APP_TABS_CLOSE_ALL,
	}),

	// Helpers
	openTabUsers: () => {
		const tab = {
			id            : 'users/list',
			titleID       : 'sidebar.usersSearch',
			componentName : COMPONENTS.usersList,
			componentProps: {},
			location      : '/dashboard/users/list',
		};

		return actions.openTab(tab);
	},
	openTabUsersGroups: () => {
		const tab = {
			id            : 'users/groups',
			titleID       : 'sidebar.userGroups',
			componentName : COMPONENTS.usersGroups,
			componentProps: {},
			location      : '/dashboard/users/groups',
		};

		return actions.openTab(tab);
	},
	openTabUsersRoles: () => {
		const tab = {
			id            : 'users/roles',
			titleID       : 'sidebar.userRoles',
			componentName : COMPONENTS.usersRoles,
			componentProps: {},
			location      : '/dashboard/users/roles',
		};

		return actions.openTab(tab);
	},

	openTabWebsiteInner: (websiteID) => {
		const tab = {
			id            : 'partners/websitesInfo',
			titleID       : 'websiteInner.title',
			componentName : COMPONENTS.websiteInner,
			componentProps: { websiteID },
			location      : `/dashboard/partners/websitesInfo/${websiteID}`,
		};

		return actions.openTab(tab);
	},

	openTabUserInfo: (userID, itemID) => {
		const htmlId = `itemID_${isNumber(itemID) ? itemID : ''}`;
		const tempTab = itemID ? ADDITIONAL_TABS.userInfo : ADDITIONAL_TABS.personalInfo;
		const location = itemID ? `/dashboard/users/info/${userID}#${htmlId}` : `/dashboard/personal/info/${userID}`;

		const tab = {
			...tempTab,
			componentProps: { userID: toInteger(userID) },
			location,
		};
		if (itemID) {
			routes.navigate(location);
		}

		return actions.openTab(tab);
	},

	openTabInfoPage: pageID => {
		const tab = {
			...ADDITIONAL_TABS.infoPage,
			componentProps: { pageID: toInteger(pageID) },
			location      : `/dashboard/content/page/${toInteger(pageID) > 0 ? pageID : 'add'}`,
			titleID       : toInteger(pageID) > 0 ? 'infoPages.editTitle' : 'infoPages.newTitle',
		};

		return actions.openTab(tab);
	},
	openPromotionPage: promotionID => {
		const tab = {
			...ADDITIONAL_TABS.promotions,
			componentProps: { promotionID: toInteger(promotionID) },
			location      : `/dashboard/content/newPromotion/${toInteger(promotionID) > 0 ? promotionID : 'add'}`,
			titleID       : toInteger(promotionID) > 0 ? 'promotion.editTitle' : 'promotion.newTitle',
		};

		return actions.openTab(tab);
	},
	openTabNewsArticlePage: articleID => {
		const tab = {
			...ADDITIONAL_TABS.newsArticlePage,
			componentProps: { articleID: toInteger(articleID) },
			location      : `/dashboard/content/news/article/${toInteger(articleID) > 0 ? articleID : 'add'}`,
			titleID       : toInteger(articleID) > 0 ? 'news.article.editTitle' : 'news.article.newTitle',
		};

		return actions.openTab(tab);
	},
	openTabPayways: paymentID => {
		const tab = {
			...ADDITIONAL_TABS.paymentPayways,
			componentProps: { paymentID: toInteger(paymentID) },
			location      : `/dashboard/payment/payways/${paymentID}`,
		};

		return actions.openTab(tab);
	},

	openRTMSubModules: (address, componentName) => {
		const tab = {
			id            : `rtm/${address}`,
			titleID       : `sidebar.rtm.${address}`,
			componentName : COMPONENTS[componentName],
			componentProps: {},
			location      : `/dashboard/rtm/${address}`,
		};

		return actions.openTab(tab);
	},

	openTabMessages: (val) => {
		let url = '/dashboard/notificationCenter/messages';
		if (val) {
			url = '/dashboard/notificationCenter/messages/compose';
		}
		const tab = {
			id            : 'notificationCenter/messages',
			titleID       : 'sidebar.messages',
			componentName : COMPONENTS.messages,
			componentProps: {},
			location      : url,
		};

		return actions.openTab(tab);
	},
};

export default actions;
