import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import actions from './actions';
import listActions from '../customProviders/actions';

import { casinoAPI } from '../../../helpers/api/casino';
import { showError, showSuccess } from '../../../helpers/notifications';
import { isID } from '../../../helpers/utils';

import { adaptCustomProvider, prepareCustomProvider, adaptNamesData, prepareNamesData } from './utils';
import { createListAdapter, updateCustomProvidersEntities } from '../customProviders/utils';
import { restoreWebsite } from '../../../helpers/utility';
import { logger } from '../../../helpers/logger';

const messages = {
	errorBaseDataReload: 'casino.customProvider.error.baseData.reload',
	errorBaseDataSave  : 'casino.customProvider.error.baseData.save',
	errorNamesReload   : 'casino.customProvider.error.names.reload',
	errorNamesUpdate   : 'casino.customProvider.error.names.update',
	errorAliasUpdate   : 'casino.customProvider.error.alias.update',
	successBaseDataSave: 'casino.customProvider.success.baseData.save',
	successNamesUpdate : 'casino.customProvider.success.names.update',
};

function getStoreData({ Casino, App, LanguageSwitcher }) {

	return {
		entities           : Casino.CustomProviders.get('entities'),
		baseData           : Casino.CustomProvider.get('baseData'),
		namesData          : Casino.CustomProvider.get('namesData'),
		checkedList        : Casino.CustomProvider.get('checkedList'),
		UI                 : Casino.CustomProvider.get('UI'),
		language           : LanguageSwitcher.get('language'),
		websiteID          : App.get('websiteID'),
		contentLanguageList: App.get('websiteAttachedLanguages'),
	};
}

function* baseDataReload() {

	yield takeEvery(actions.CASINO_CUSTOM_PROVIDER_BASE_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));

		const storeData = yield select(getStoreData);
		const { customProviderID, forced } = action.data;

		// data from list
		let baseData = cloneDeep(storeData.baseData) || {};
		if (isID(baseData.id) && !forced) {
			yield put(actions.uiRefresh({
				loading      : false,
				isBaseChanged: false,
			}));

			return;
		}

		// loading data
		try {
			const res = yield call(casinoAPI.customProvidersInfo, customProviderID);
			if (res && res.status === 200) {
				baseData = adaptCustomProvider(res.data.data);
			}
		} catch (error) {
			showError(messages.errorBaseDataReload);
		}

		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.uiRefresh({
			loading      : false,
			isBaseChanged: false,
		}));
	});
}

function* customProviderSave() {

	yield takeEvery(actions.CASINO_CUSTOM_PROVIDER_SAVE, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		const { closeModal } = data;
		const { baseData, namesData, checkedList, UI, websiteID, contentLanguageList, entities } = yield select(getStoreData);
		const { editMode, isNamesChanged, isGamesChanged, isAliasChanged, isSeoChanged } = UI;
		let { customProviderID } = UI;

		const customProviderBaseData = cloneDeep(baseData);
		const customProviderNamesData = cloneDeep(namesData);
		let isError = false;
		let errorMessage = '';

		try {
			// base data
			errorMessage = messages.errorBaseDataSave;
			const preparedData = prepareCustomProvider(customProviderBaseData);
			preparedData.website_id = websiteID;
			preparedData.game_ids = [];
			const games = Object.values(checkedList);
			games.forEach(game => {
				preparedData.game_ids = preparedData.game_ids.concat(game);
			});

			if (editMode) {
				if (!isGamesChanged) {
					delete preparedData.game_ids;
				}

				const updatedEntities = updateCustomProvidersEntities(entities, null, customProviderID,true);
				yield put(listActions.listRefresh(updatedEntities));

				const res = yield call(casinoAPI.customProvidersUpdate, customProviderID, preparedData);
				if (res && res.status === 200) {
					const defaultName = customProviderNamesData[0].name;
					const updatedEntities = updateCustomProvidersEntities(entities, { ...baseData, name: defaultName }, customProviderID,false);
					yield put(listActions.listRefresh(updatedEntities));
					const listAdapter = createListAdapter();
					const adaptedData = listAdapter.adapt(res.data.data);
					yield put(actions.baseDataRefresh({ ...adaptedData, name: baseData.name }));
					yield put(listActions.reloadItem(res.data.data));
				}
			} else {
				const res = yield call(casinoAPI.customProvidersCreate, preparedData);
				if (res && res.status === 200) {
					customProviderID = toInteger(res.data.data.id);

					yield put(actions.uiRefresh({
						customProviderID,
						editMode: true,
					}));
				}
			}

			yield put(actions.uiRefresh({ isBaseChanged: false }));
			const params = {
				website_id: restoreWebsite(),
			};

			const preparedList = prepareNamesData(customProviderNamesData, customProviderID);

			if (isNamesChanged && isAliasChanged) { //both
				errorMessage = messages.errorBaseDataSave;
				const preparedList = prepareNamesData(customProviderNamesData, customProviderID);
				const res = yield call(casinoAPI.customProvidersNamesUpdate, customProviderID, preparedList, params);
				const nameData = adaptNamesData(res.data.data, namesData.name, contentLanguageList);
				yield put(actions.namesDataRefresh(nameData));
				yield put(actions.uiRefresh({ isNamesChanged: false, isAliasChanged: false }));
			} else if (isNamesChanged || isSeoChanged) { // names
				errorMessage = messages.errorNamesUpdate;
				const res = yield call(casinoAPI.customProvidersNamesUpdate, customProviderID, preparedList, params);
				const nameData = adaptNamesData(res.data.data, namesData.name, contentLanguageList);
				yield put(actions.namesDataRefresh(nameData));
				yield put(actions.uiRefresh({ isNamesChanged: false }));
			} else if (isAliasChanged) { //alias
				errorMessage = messages.errorAliasUpdate;
				const res = yield call(casinoAPI.customProvidersNamesUpdate, customProviderID, preparedList, params);
				const nameData =  adaptNamesData(res.data.data, namesData.name, contentLanguageList);
				yield put(actions.namesDataRefresh(nameData));
				yield put(actions.uiRefresh({ isAliasChanged: false }));
			}

			showSuccess(messages.successBaseDataSave);

		} catch (error) {
			const updatedEntities = updateCustomProvidersEntities(entities, null, customProviderID,false);
			yield put(listActions.listRefresh(updatedEntities));

			isError = true;
			showError(errorMessage);
		}

		if (!isError && !closeModal) {
			// yield put(actions.baseDataReload(customProviderID, true));
			if (isNamesChanged || isAliasChanged) {
				yield put(actions.namesDataReload(customProviderID));
			}
		}

		const visible = !(closeModal && !isError);

		yield put(actions.uiRefresh({
			loading: false,
			visible,
		}));
		if (!visible) {
			yield put(actions.dataReset());
		}
	});
}

function* namesDataReload() {

	yield takeEvery(actions.CASINO_CUSTOM_PROVIDER_NAMES_DATA_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ loading: true }));
		const { namesData, contentLanguageList } = yield select(getStoreData);

		const { customProviderID } = action.data;
		let nameData = adaptNamesData([], namesData.name, contentLanguageList);
		try {
			const res = yield call(casinoAPI.customProvidersNamesList, customProviderID);
			if (res && res.status === 200) {
				nameData = adaptNamesData(res.data.data, namesData.name, contentLanguageList);
			}

		} catch (error) {
			showError(messages.errorNamesReload);
			logger.log(error);
		}

		yield put(actions.namesDataRefresh(nameData));
		yield put(actions.uiRefresh({
			loading       : false,
			isNamesChanged: false,
		}));
	});
}

export default function* customProviderSaga() {
	yield all([
		fork(baseDataReload),
		fork(customProviderSave),
		fork(namesDataReload),
	]);
}
